const portfolioImages = [
  require("./portfolio-pages/deercoded.png"),
  require("./portfolio-pages/deercoded-p2.png"),
  require("./portfolio-pages/deercodedmobile.png"),
  require("./portfolio-pages/super-donair.png"),
  require("./portfolio-pages/super-donair-mobile.png"),
  require("./portfolio-pages/ereader.png"),
  require("./portfolio-pages/ereader-mobile.png"),
  require("./portfolio-pages/bluebear.png"),
  require("./portfolio-pages/deeracode.png"),
  require("./portfolio-pages/greenframe.png"),
  require("./portfolio-pages/crm.png"),
  // require("./portfolio-pages/.png"),
];

const blogImages = [
  {
    id: 0,
    article: require("./blogs/react.jpeg"),
    main: require("./blogs/react.jpeg"),
  },

  {
    id: 1,
    article: require("./blogs/interview.webp"),
    main: require("./blogs/interview.webp"),
  },

  {
    id: 2,
    article: require("./blogs/3-reasons-fail.jpg"),
    main: require("./blogs/3-reasons-fail.jpg"),
  },
  {
    id: 3,
    article: require("./blogs/cryptoboom.jpg"),
    main: require("./blogs/cryptoboom.jpg"),
  },
  {
    id: 4,
    article: require("./blogs/5-css.jpg"),
    main: require("./blogs/5-css.jpg"),
  },
  {
    id: 5,
    article: require("./blogs/csstips.jpg"),
    main: require("./blogs/csstips.jpg"),
  },
  {
    id: 6,
    article: require("./blogs/layoffs.jpg"),
  },
  {
    id: 7,
    article: require("./blogs/codingjourney.jpg"),
  },
  {
    id: 8,
    article: require("./blogs/recession.jpg"),
  },
  {
    id: 9,
    article: require("./blogs/webflow.jpg"),
  },
  {
    id: 10,
    article: require("./blogs/design.jpg"),
  },
  {
    id: 11,
    article: require("./blogs/progress.jpg"),
  },
  {
    id: 12,
    article: require("./blogs/pbuddies.jpg"),
  },
  {
    id: 13,
    article: require("./blogs/php.jpg"),
  },
];

module.exports = { blogImages, portfolioImages };
